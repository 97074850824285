.page-dataset {

}

    .page-dataset .thick-bar {
        /* background-color: #37394b; */
        height: 0;
        margin: 0;
        padding: 1px 0;
    }

.page-dataset .MuiPaper-elevation1 {
    box-shadow: none;
}

.MuiAccordion-root:before {
    background-color: transparent !important;
}

.page-dataset .page-dataset-visual-selection-container {
    width: 100%;
    text-align: right;
}

.page-dataset .page-dataset-visual-selection-container .page-dataset-visual-selection {
    display: inline-block;
}

.page-dataset .page-dataset-breadcrumb {
    padding-top: 60px;
    padding-left: 160px;
    padding-right: 160px;
}

.page-dataset .page-dataset-breadcrumb .breadcrumb {
    padding: 0;
    margin-bottom: 0;
}

.page-dataset .page-dataset-heading {
    padding-bottom: 55px;
    padding-left: 160px;
    padding-right: 160px;
    padding-top: 30px;
}

.page-dataset-section {
    padding-bottom: 55px;
    padding-left: 80px;
    padding-right: 80px;
}

.splitter {
    background-color: #37394b;
    height: 2px;
    margin: 30px 0;
    width: 100%;
}

.splitter.wide {
    margin-left: -80px;
    width: calc(100% + 160px);
}

.powerbi-embed-container {
    height: 740px;
    margin: 30px 0;
}

.powerbi-embed-container iframe {
    border: none;
}

.powerbi-embed-container-visual-only {
    height: 720px;
    margin: 0 0;
}

.powerbi-embed-container-visual-only iframe {
    border: none;
}

.powerbi-embed-container iframe {
    border: 0;
}