.page-home {
    width: 100%;
}

    .page-home .page-home-section {
        padding-left: 80px;
        padding-right: 80px;
        padding-top: 55px;
    }

    .page-home .page-home-heading {
        padding-bottom: 55px;
        padding-left: 160px;
        padding-right: 160px;
        padding-top: 65px;
    }

    .page-home .panel-container-updates-frequently-visited {
        width: 100%;
    }

    .page-home .panel-container-updates-frequently-visited > div {
        display: inline-block;
    }

        .page-home .panel-container-updates-frequently-visited .panel-container-updates {
            vertical-align: top;
            width: 300px;
        }

        .page-home .panel-container-updates-frequently-visited .panel-container-frequently-visited {
            margin-left: 40px;
            vertical-align: top;
            width: calc(100% - 340px);
        }