.header-top-bar {
    background: #37394B;
    display: flex;
    height: 84px;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1440px;
    padding: 0 24px 0 24px;
    width: 100%;
    font-size:22px;
}

.header-top-bar .group {

}