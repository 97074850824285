.panel-updates {
    margin-top: 7px;
    display: block;
    width: 300px;
}

    .panel-updates .panel-title {
        border-bottom: 2px solid #37394b;
        font-family: 'Gibson';
        font-size: 23px;
        font-style: normal;
        font-weight: 900;
        line-height: 27px;
        padding: 10px 0;
        width: 100%;
    }

.panel-updates .update {
    border-bottom: 1px solid #c6c6c6;
    padding: 10px 0;
    width: 100%;
}

.panel-updates .update:hover {
    border-bottom: 1px solid #7bb1b6;
}

.panel-updates .update .update-type-and-timestamp-container {
    padding: 5px 0;
    width: 100%;
}

.panel-updates .update .update-type-and-timestamp-container .update-type-shape {
    background-color: #86bc25;
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    width: 10px;
}

.panel-updates .update .update-type-and-timestamp-container .update-type-name {
    color: #257d86;
    font-family: 'Gibson';
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    padding-left: 4px;
    line-height: 15px;
}

.panel-updates .update .update-type-and-timestamp-container .update-timestamp {
    color: #37394b;
    font-family: 'Gibson';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    padding-left: 4px;
    line-height: 15px;
}

.panel-updates .update .update-indicator-container {
    width: 100%;
}

.panel-updates .update .update-indicator-container .update-indicator-name {
    color: #37394b;
    display: inline-block;
    font-family: 'Gibson';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    width: calc(100% - 30px);
}

.panel-updates .update:hover .update-indicator-container .update-indicator-name {
    color: #7bb1b6;
}

.panel-updates .update .update-indicator-container .update-indicator-chevron {
    color: #7bb1b6;
    display: inline-block;
    height: 100%;
    vertical-align: top;
    width: 30px;
}