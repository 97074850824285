

@font-face {
    font-family: 'Gibson';
    src: url('assets/fonts/Gibson/Gibson-Book.eot'); /* IE9 Compat Modes */
    src: url('assets/fonts/Gibson/Gibson-Book.woff2') format('woff2'), /* Super Modern Browsers */
    url('assets/fonts/Gibson/Gibson-Book.woff') format('woff'), /* Modern Browsers */
    url('assets/fonts/Gibson/Gibson-Book.ttf') format('truetype'); /* Safari, Android, iOS */
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'SCW Icons';
    font-style: normal;
    font-weight: normal;
    src: url('assets/fonts/SCW Icons/SCW_Icons.eot');
    src: url('assets/fonts/SCW Icons/SCW_Icons.eot?#iefix') format('embedded-opentype'), url('assets/fonts/SCW Icons/SCW_Icons.woff2') format('woff2'), url('assets/fonts/SCW Icons/SCW_Icons.woff') format('woff'), url('assets/fonts/SCW Icons/SCW_Icons.ttf') format('truetype');
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url('assets/fonts/Material Icons/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2') format('woff2');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: #e5e5e5;
    font-family: gibson;
    min-width: 1000px;
}

body.zoom {
    zoom: 125%;
}

a {
    color: #7bb1b6;
}

a:hover {
    color: #7bb1b6;
    font-weight: bolder;
}


.dropdown-item:active {
    color: #000;
    text-decoration: none;
    background-color: #bdd7d9;
}

textarea:focus, input:focus {
    outline: none;
    box-shadow: none;
}

.table {
    margin-bottom: 0;
}

div.throbber-center {
    height: 80px;
    width: 80px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -40px;
    margin-left: -40px;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.scw-icon {
    display: inline-block;
    font-family: "SCW Icons";
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    padding-right: 12px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}

.scw-icon-accessibility::before {
    content: "\e000";
}
.scw-icon-social-facebook::before {
    content: "\e001";
}
.scw-icon-social-twitter::before {
    content: "\e002";
}
.scw-icon-social-youtube::before {
    content: "\e003";
}
.scw-icon-zoom-set::before {
    content: "\e004";
}
.scw-icon-zoom-unset::before {
    content: "\e005";
}


.chevron::before {
    border-style: solid;
    border-width: 0.02em 0.02em 0 0;
    content: '';
    display: inline-block;
    height: 0.6em;
    position: relative;
    top: 0.5em;
    transform: rotate(-45deg);
    vertical-align: top;
    width: 0.7em;
    z-index: 0;
}

.chevron.right:before {
    left: 0;
    transform: rotate(45deg);
}

.chevron.bottom:before {
    top: 0;
    transform: rotate(135deg);
}

.chevron.left:before {
    left: 0.25em;
    transform: rotate(-135deg);
}


.thick-bar {
    background-color: #7bb1b6;
    height: 6px;
    margin: 20px 0;
    padding: 2px 0;
}


/* The container */
.checkbox-label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkbox-checkmark {
    background-color: transparent;
    border: 1px solid #37394b;
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
}

/* Custom checkbox */

/* On mouse-over, add a grey background color */
.checkbox-label:hover input ~ .checkbox-checkmark {
    background-color: #bdd7d9;
}

.checkbox-label input:checked ~ .checkbox-checkmark {
    background-color: transparent;
}

.checkbox-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-label input:checked ~ .checkbox-checkmark:after {
    display: block;
}

.checkbox-label .checkbox-checkmark:after {
    left: 6px;
    top: 2px;
    width: 7px;
    height: 12px;
    border: solid #7bb1b6;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* End of custom checkbox */


.btn {
    border-radius: 15px;
}

.btn-secondary {
    color: #37394b;
    background-color: #d8d8d8;
    border-color: #d8d8d8;
}

    .btn-secondary.active {
        background-color: #37394B;
        color: #fff;
    }

.page-container {
    background-color: #fff;
    padding-right: 0;
    padding-left: 0;
}

@media (min-width: 576px) {
    .container {
         max-width: 1000px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 1000px;
    }
}

@media (min-width: 1440px) {
    .container {
         max-width: 1440px;
    }
}

