.header-bar-bottom {
    background: #247c86;
    color: #fff;
    height: 84px;
    /* position: absolute; */
    margin: 0 auto;
    max-width: 1440px;
    padding-left: 37px;
    padding-right: 37px;
    padding-top: 0px;
    padding-bottom: 0px;
    top: 51px;
    width: 100%;
}

.header-bar-bottom .table {
    width: 100%;
}

.header-bar-bottom .table .cell-logo {
    padding: 5px 50px 0 0;
    width: 22.4087%;

}

.header-bar-bottom .table .cell-logo .logo {
    height: 62px;
    width: 257px;
}

    .header-bar-bottom .table .cell-title {
        border-left: 1px #2D9281 solid;
        height: 44px;
    }

    .header-bar-bottom .table .cell-title .title {
        color: #fff;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 57px;
        font-family: Gibson;
        white-space: nowrap;
    }

.header-bar-bottom .table .cell-button-search {
}