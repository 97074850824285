.button-custom-group {
    position: initial;
    margin-top: -16px;
}

.button-custom-group-selected.btn-secondary {
    background-color: #37394b;
    color: #fff;
}

.btn-group {
    display: block;
}

.btn-group .btn, .btn-group .btn:focus, .btn-group .btn:active:focus
.btn-group .btn-secondary, .btn-group .btn-secondary:focus, btn-group .btn-secondary:active:focus {
    box-shadow: none;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
    border-radius: 0;
    position: initial;
}