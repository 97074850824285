html {
    font-size: 14px;
}

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

.navbar-custom {
    background-color: #bdd7d9;
    margin: 0 auto;
    max-width: 1440px;
    padding-bottom: 0;
    width: 100%;
}

    .navbar-custom.mb-3, 
    .navbar-custom.my-3 {
        margin-bottom: 0 !important;
    }

    .navbar-custom .navbar-nav .nav-item {
        border-bottom: 3px solid transparent;
        color: #fff;
        padding-left: 16px;
        padding-right: 16px;
    }

    .navbar-custom .navbar-nav .nav-item:hover {
        border-bottom: 3px solid #007B67;
        color: #fff;
    }

.navbar-custom .navbar-nav .nav-item .nav-link {
    color:  #37394b;
    font-size: 18px;
    font-weight: 1000;
    font-family:Gibson;
}

@media (min-width: 768px) {
    html { font-size: 16px; }
}

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }
