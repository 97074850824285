.search-panel-container {
    background: #37394b;
    color: #fff;
    padding: 0 220px;
    width: 100%;
}

.search-panel-container .search-panel-title {
    height: 80px;
    font-weight: bolder;
    font-size: x-large;
    line-height: 80px;
    padding-top: 10px;
}

.search-panel-input {
    height: 85px;
}

.search-panel-input .input-group {
    height: 100%;
}

.search-panel-input .input-group .input-group-text {
    background-color: #fff;
    border: none;
    border-radius: 0;
    padding-left: 25px;
}

.search-panel-input .input-group input {
    border: 0;
    height: 100%;
}

.search-panel-input .input-group button {
    background-color: #7bb1b6;
    border-radius: 10px;
    color: #fff;
}

.search-panel-input-container-button {
    background-color: #fff;
    padding: 21px;
}

.search-panel-input-hint {
    font-size: medium;
    height: 80px;
    padding-top: 15px;
}