.page-category .page-category-breadcrumb {
    padding-top: 60px;
    padding-left: 160px;
    padding-right: 160px;
}
.page-category .page-category-breadcrumb .breadcrumb {
    padding: 0;
    margin-bottom: 0;
}

.page-category .page-category-heading {
    padding-bottom: 55px;
    padding-left: 160px;
    padding-right: 160px;
    padding-top: 30px;
}

.page-category .page-category-section {
    padding-bottom: 55px;
    padding-left: 80px;
    padding-right: 80px;
}

.page-category .panel-container-updates-frequently-visited {
    width: 100%;
}

.page-category .panel-container-updates-frequently-visited > div {
    display: inline-block;
}

    .page-category .panel-container-updates-frequently-visited .panel-container-updates {
        vertical-align: top;
        width: 300px;
    }

    .page-category .panel-container-updates-frequently-visited .panel-container-frequently-visited {
        margin-left: 40px;
        vertical-align: top;
        width: calc(100% - 340px);
    }
