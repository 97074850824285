.page-article {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: normal;
    align-content: normal;
    /*Line added below*/
    width: 100%;
    /*padding-right: 110px;*/
}

.page-article-body {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: normal;
    align-content: normal;
    /*Line added below*/
    width: 100%;
    min-width: 768px;
    /*padding-right: 110px;*/
}

.page-article-body .page-article-toc {
    align-self: auto;
    display: block;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    min-width: 20%;
    max-width: 20%;
    order: 0;
    padding: 20px 20px;
}

.page-article-body .page-article-toc nav {
    align-self: start;
    position: sticky;
    top: 2rem;
}

.page-article-body .page-article-toc h1 {
    font-weight: bold;
    font-size: 1.5rem;
}

.page-article-body .page-article-toc ul {
    list-style: none;
    padding: 0;
}

.page-article-body .page-article-toc li {
    border-left: 2px solid #f2f2f2;
    font-size: 1.2rem;
    font-weight: normal;
    padding-left: 10px;
    text-decoration: blink;
    margin-bottom:29px;
}

.page-article-body .page-article-toc li:hover {
    border-left: 2px solid #257d86;
    font-size: 1.2rem;
    font-weight: normal;
    padding-left: 10px;
    text-decoration: blink;
}

.page-article-body .page-article-toc li.active {
    border-left: 2px solid #257d86;
    font-size: 1.3rem;
    font-weight: normal;
    padding-left: 10px;
    text-decoration: blink;
    margin-bottom:29px;
}

.page-article-body .page-article-toc li a.page-article-toc-link {
    color: #706f74;
    transition: all 100ms ease-in-out;
}

.page-article-body .page-article-toc li.active a.page-article-toc-link {
    color: #257d86;
    font-weight: bold;
    transition: all 100ms ease-in-out;
}

.page-article-body .page-article-toc li a.page-article-toc-link:hover {
    color: #257d86;
}
    
.page-article-body .page-article-main {
    display: block;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
    margin-left: 2rem;
    margin-right:4rem;
    /*Line added below*/
    width: 100%;
}

.page-article-keyfacts {
    /*background-color: #f2f2f2;*//*removing the background colour*/
}

.page-article-section {        
    padding: 10px 20px;
}

.page-article-powerBIReport {
    display: block;
    width: 100%;
    height: 720px;
    /*Line below added after*/
    min-width: 768px;
    /*padding-right:110px*/
}

.page-article-powerBIReport iframe {
    width: 100%;  
    height: 100%;
    /*Line below added after*/
    min-width: 768px;
    /*padding-right: 110px*/
}

.page-article-image {
    text-align: center;
}

.page-article-summary {
    background-color: #ffffff;
    border: 4px solid #247c86;
    margin-bottom: 2rem;
}

.page-article-header {
    /*background-color: #fafafa;*/ /*Removing Header background colour*/
    padding: 125px 0px;
    margin-left:2rem;
}

.page-article-header .page-article-header-metadata {
    align-content: normal;
    align-items: normal;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-top: 2rem;
}

.page-article-header .page-article-header-metadata .page-article-header-metadata-item {
    display: block;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
}

.page-article-header {
    
}

.page-article-header h1 {
    color: #37394B;
    font-weight: 900;
    font-size: 50px;
    line-height: 59px;
}

.page-article-header h2 {
    color: #37394B;
    font-weight: 400;
    font-size: 22px;
    line-height: 36px;
}

.page-article-header .page-article-header-metadata .page-article-header-metadata-item h1 {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.25rem;
}

.page-article-footer {
    padding: 30px 60px;
}

.page-article-contact {

}

.page-article-section img {
    width: 100% !important;
}