.green-type-1 {
    background: #bdd7d9;
    border: none;
    border-radius: 5px;
    display: inline-block;
    height: 155px;
    margin: 0 10px;
    width: 255px;
}

.green-type-1 .powerbi-embed-container {
    height: 149px;
    margin: 3px;
    width: 249px;
}

.green-type-1 .powerbi-embed-container iframe {
    border: 0;
}
