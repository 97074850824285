.button-header-bar-top {
    background-color: transparent;
    border: none;
    color: #fff;
    height: 50px;
    margin-top: 14px;
}

    .button-header-bar-top:hover {
        background-color: #212129;
        border-radius: 10px;
    }

    .button-header-bar-top.active {
        /*border-bottom: 3px #6BC9A7 solid;*/
        background-color: #212129;
        border-radius: 10px;
    }

.button-header-bar-top .icon {
    vertical-align: middle;
}

.button-header-bar-top .label {
    font-size: 17px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
}

