.panel-indicator-tile {
    border-bottom: 1px solid #c6c6c6;
    display: inline-block;
    padding: 10px 0;
    max-width: 280px;
    width: 100%;
}

.panel-indicator-tile:hover {
    border-bottom: 1px solid #7bb1b6;
}

.panel-indicator-tile .panel-indicator-tile-type-and-timestamp-container {
        padding: 5px 0;
        width: 100%;
    }

panel-indicator-tile .panel-indicator-tile-type-and-timestamp-container .panel-indicator-tile-type-shape {
    background-color: #86bc25;
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    width: 10px;
}

.panel-indicator-tile .panel-indicator-tile-type-and-timestamp-container .panel-indicator-tile-type-name {
    color: #257d86;
    font-family: 'Gibson';
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    padding-left: 0;
    line-height: 15px;
}

.panel-indicator-tile .panel-indicator-tile-type-and-timestamp-container .panel-indicator-tile-timestamp {
    color: #37394b;
    font-family: 'Gibson';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    padding-left: 4px;
    line-height: 15px;
}

.panel-indicator-tile .panel-indicator-tile-indicator-container {
    width: 100%;
}

    .panel-indicator-tile .panel-indicator-tile-indicator-container .panel-indicator-tile-indicator-name {
        color: #37394b;
        display: inline-block;
        font-family: 'Gibson';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 19px;
        width: calc(100% - 30px);
    }

    .panel-indicator-tile:hover .panel-indicator-tile-indicator-container .panel-indicator-tile-indicator-name {
        color: #7bb1b6;
    }

.panel-indicator-tile .panel-indicator-tile-indicator-container .panel-indicator-tile-indicator-chevron {
    color: #7bb1b6;
    display: inline-block;
    height: 100%;
    vertical-align: top;
    width: 30px;
}