.categories-container {
    padding-bottom: 50px;
    width: 100%;
}

.categories-container table.table {
    margin-bottom: 0;
}

.categories-container td {
    padding: 0;
}

.categories-container .categories-title {
    color: #37394b;
    font-family: 'Gibson';
    font-size: 33px;
    font-style: normal;
    font-weight: 900;
    line-height: 39px;
    padding: 30px 0 0 0;
    width: 100%;
}

    .categories-container .categories-major {

        width: 100%;
    }


        .categories-container .categories-major .panel {
            width: 100%;
        }