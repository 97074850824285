.link-see {
    color: #7bb1b6;
    font-family: 'Gibson';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    padding: 10px 0;
    width: 100%;
}

.link-see:hover {
    font-weight: bolder;
}

.link-see .chevron:before {
    margin-left: 8px;
    margin-top: 4px;
}

.link-see .chevron.right:before {
    margin-top: 0;
}