.panel-search-result-list-container {
    background-color: #fff;
    border: 1px solid #c6c6c6;
    height: 600px;
    overflow: auto;
    position: relative;
}

    .panel-search-result-list-container {
        z-index: 9;
    }

    .panel-search-result-list {
        left: 0;
        margin-bottom: 10px;
        position: absolute;
        top: 0;
        width: 100%;
    }

.panel-search-result-list .list-group-item {
    color: #37394b;
    border-radius: 0;
    border: 0;
    border-top: 1px solid #c6c6c6;
}

.panel-search-result-list .list-group-item:hover {
    background: #bdd7d9;
}

.panel-search-result-list .list-group-item a {
    color: #37394b;
}

.panel-search-result-list .list-group-item .indicator-name {
    display: inline-block;
    float: left;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    width: calc(100% - 100px);
}

.panel-search-result-list .list-group-item .indicator-type-name {
    color: #257d86;
    display: inline-block;
    float: right;
    font-size: 13px;
    font-weight: bold;
    width: 100px;
}

.panel-search-result-list .list-group-item-see-all {
    color: #7bb1b6;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
}