.page-about {
    
}

.page-about .page-about-heading {
    padding-bottom: 55px;
    padding-left: 160px;
    padding-right: 160px;
    padding-top: 65px;
}

.page-about .page-about-section {
    padding-bottom: 55px;
    padding-left: 80px;
    padding-right: 80px;
    width: 100%;
}