.footer {
    background-color: #37394b;
    color: #fff;
    padding-top: 60px;
    padding-bottom: 85px;
    margin: 0 auto;
    width: 100%;
    max-width: 1440px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.footer a {
    text-decoration: none;
    color: #fff;
}

.footer .u-padding {
    padding-left: 50px;
    padding-right: 50px;
}

.footer .row::before {
    display: table;
    content: ' ';
}

.footer .row {
    max-width: 73.5rem;
    margin-right: auto;
    margin-left: auto;
}

.footer .table {

}

.footer .table td {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 30px;
}

.footer .table .link-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.footer .table .link-list a {
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    line-height: 23px;
}

.footer .table .link {

}

.footer .table .logo {
    height: 58px;
    width: 170px;
}

.footer .details {
    width: 100%;
}

.footer .details .contact {
    float: left;
}

.footer .details .contact .email {
    padding-right: 30px;
}

.footer .details .social-channels {
    float: right;
    padding-right: 86px;
}

.footer .details .social-channels .scw-icon {
    font-size: 24px;
    margin-right: 30px;
}