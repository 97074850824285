.page-articles {
    width: 100%;
}

    .page-articles .page-articles-section {
        padding-left: 80px;
        padding-right: 80px;
        padding-top: 55px;
    }
	
	.page-articles .panel-container-updates-frequently-visited {
        width: 100%;
    }
	
	.page-articles .panel-container-updates-frequently-visited > div {
        display: inline-block;
    }

        .page-articles .panel-container-updates-frequently-visited .panel-container-updates {
            vertical-align: top;
            width: 300px;
        }

        .page-articles .panel-container-updates-frequently-visited .panel-container-frequently-visited {
            margin-left: 40px;
            vertical-align: top;
            width: calc(100% - 340px);
        }