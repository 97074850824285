.panel-metadata {
    margin-bottom: 80px;
}

    .panel-metadata .panel-title {
        color: #37394b;
        font-size: 23px;
        font-weight: 900;
        line-height: 27px;
        text-align: left;
        width: 220px;
    }


.panel-metadata-cell-group-selection {
    text-align: left;
}

.panel-metadata-item-cell-title {

    color: #37394b;
    font-size: 16px;
    font-weight: 900;
    line-height: 19px;
    text-align: right;
}

.panel-metadata-item-cell-description {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
}

    .panel-metadata-item-cell-title.border-bottom,
    .panel-metadata-item-cell-description.border-bottom {
        border-bottom: 1px solid #37394b !important;
    }
