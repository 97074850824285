.panel-select-indicator-container {
    background: #f9f8f9;
    min-height: 300px;
    max-height: 600px;
    overflow: auto;
    position: relative;
    width: 100%;
}

.toolbar {
    
    padding-bottom: 20px;
    text-align: left;
    width: 100%;
}

.toolbar > div {
    display: inline-block;
}

.toolbar .select-all,
.toolbar .select-none {
    display: inline-block;
    color: #247c86;
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
}

.toolbar .select-none {
    margin-left: 20px;
}

.panel-select-indicator {
    left: 0;
    margin-bottom: 10px;
    padding: 0 0 0 10px;
    position: absolute;
    top: 0;
    width: 100%;
}
    
    .panel-select-indicator .list-group-item {
        color: #37394b;
        background: transparent;
        border-radius: 0;
        border: 0;
        border-top: 1px solid #c6c6c6;
    }

        .panel-select-indicator .list-group-item.selected {
            background: #bdd7d9;
        }

    .panel-select-indicator .list-group-item:hover {
        background: #bdd7d9;
    }
    

    .panel-select-indicator .list-group-item a {
        color: #37394b;
    }

    .panel-select-indicator .indicator-selection {
        background-color: #fff;
        border: 1px solid #a2a2a2;
        border-radius: 50%;
        display: inline-block;
        float: left;
        height: 20px;
        margin: 0 5px 0 5px;
        width: 20px;
    }

        .panel-select-indicator .indicator-selection.selected {
            background: radial-gradient(50% 50% at 50% 50%, #fff 47.4%, #7bb1b6 47.41%);
            border-radius: 15px;
        }

    .panel-select-indicator .multiple-indicator-selection-container {
        cursor: pointer;
        display: block;
        float: left;
        font-size: 20px;
        margin: 0 5px 0 5px;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .panel-select-indicator .multiple-indicator-selection-container .multiple-indicator-selection {
        background-color: #fff;
        border: 1px solid #a2a2a2;
        border-radius: 10%;
        display: inline-block;
        height: 20px;
        width: 20px;
    }

    .panel-select-indicator .multiple-indicator-selection-container .multiple-indicator-selection:after {
        content: "";
        position: absolute;
        display: none;
    }

    .panel-select-indicator .multiple-indicator-selection-container .multiple-indicator-selection.selected {        
        background-color: #7bb1b6;
        border: 5px solid #7bb1b6;
        border-radius: 10%;
    }

    .panel-select-indicator .multiple-indicator-selection-container .multiple-indicator-selection.selected:after {
        display: block;
    }

    .panel-select-indicator .multiple-indicator-selection-container .multiple-indicator-selection:after {
        left: 8px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .panel-select-indicator .indicator-name {
        display: inline-block;
        float: left;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        padding-left: 10px;
        padding-right: 10px;
        width: calc(100% - 130px);
    }

    .panel-select-indicator .indicator-type-name {
        color: #257d86;
        display: inline-block;
        float: right;
        font-size: 13px;
        font-weight: bold;
        width: 100px;
    }