.heading {
    display: block;
    padding-bottom: 25px;
    padding-top: 25px;
    text-align: center;
    width: 100%;
}

.heading.align-left {
    text-align: left;
}

.heading .title {
    color: #37394B;
    display: inline-block;
    font-size: 50px;
    font-style: normal;
    font-weight: 900;
    line-height: 59px;
    width: 100%;
}
/*Disabling heading description because it's the same the main heading*/
.heading .description {
    color: #FFFFFF;/*Colour changed to white*/
    display: inline-block;
    font-size: 1px;/*Font size changed to 1*/
    font-style: normal;
    font-weight: normal;
    line-height: 36px;
    width: 100%;
}/*Keeping the element, if in future there is a plan to add different sub-headings*/