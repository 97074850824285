.button-standard {
    background-color: transparent;
    border: 1px #fff solid;
    border-radius: 21px;
    color: #fff;
    padding: 3px 52px 0px 37px;
    display:flex;
    align-items:center;
    white-space: nowrap;
    justify-content:center;
}

.button-standard:hover {
    background-color: #fff;
    color: #000;
}

.button-standard .icon {
    padding-right: 4px;
    vertical-align: middle;
    margin-top: 7px;
}

.button-standard .label {
    font-family: Gibson;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    white-space: nowrap;
}