.panel-select-geographies {
    width: 100%;
}

.panel-select-geographies .MuiIconButton-root {
    color: #006c65;
    font-size: 2.5rem;
}

.panel-select-geographies .MuiSvgIcon-root {
    font-size: 3rem;
}

.panel-select-geographies .panel-select-geographies-summary.table > tbody > tr > td {
    vertical-align: middle;
}

.panel-select-geographies .panel-select-geographies-summary.table > tbody > tr > td:first-child {
    width: 220px;
}

.panel-select-geographies .panel-select-geographies-summary .panel-title {
    color: #37394b;
    font-size: 23px;
    font-weight: 900;
    line-height: 27px;
}

.panel-select-geographies .panel-select-geographies-summary .panel-group-type-selector {
}

    .panel-select-geographies .panel-select-geographies-summary .panel-group-type-selector .button-custom-group {
        margin-top: 0;
    }

.panel-select-geographies .panel-select-geographies-summary .panel-align-right {
    text-align: right;
}

.panel-select-geographies .panel-select-geographies-summary .panel-selected-count {
    color: #37394b;
    font-size: 16px;
    font-weight: 900;
    line-height: 19px;
}

.panel-select-geographies .panel-geographies-group {

}        

.panel-select-geographies .panel-geographies-group .checkbox-geography {
    color: #000000;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    margin-left: 5px;
    width: 210px;
}

.panel-select-geographies .toolbar {
    
    padding-bottom: 20px;
    text-align: left;
    width: 100%;
}

.panel-select-geographies .toolbar > div {
    display: inline-block;
}

.panel-select-geographies .toolbar .select-all,
.panel-select-geographies .toolbar .select-none {
    display: inline-block;
    color: #247c86;
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
}

.panel-select-geographies .toolbar .select-none {
    margin-left: 20px;
}