.panel-related-content {

}

.panel-related-content .panel-title {
    color: #37394b;
    font-size: 23px;
    font-weight: 900;
    line-height: 27px;
    text-align: left;
    width: 220px;
}

.panel-related-content .panel-related-content-cell-tiles div {
    margin-right: 23px;
}
