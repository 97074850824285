.page-articles-list {
    width: 100%;
}

.page-articles-list .page-articles-list-section {
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 55px;
    padding-bottom: 55px;
}

.page-articles-list .page-articles-list-section .page-articles-list-table {
    width: 100%;
}

.page-articles-list .page-articles-list-section .page-articles-list-table .order-by-chevron {
    padding-top: 6px;
    margin-left: 10px;
}

.page-articles-list .page-articles-list-section .page-articles-list-table .page-articles-list-table-row {
}

    .page-articles-list .page-articles-list-section .page-articles-list-table .page-articles-list-table-row th {
        line-height: 30px;
        padding: 0 0 20px 0;
    }

    .page-articles-list .page-articles-list-section .page-articles-list-table .page-articles-list-table-row td {
        padding: 0 10px;
        line-height: 30px;
    }

    .page-articles-list .page-articles-list-section .page-articles-list-table .page-articles-list-table-row:hover {
        /* border-bottom: 1px solid #7bb1b6; */
        color: #fff;
        background: #247c86;
    }
