.panel-select-data {
    width: 100%;
}

    .panel-select-data .MuiIconButton-root {
        color: #006c65;
        font-size: 2.5rem;
    }

    .panel-select-data .MuiSvgIcon-root {
        font-size: 3rem;
    }

    .panel-select-data .panel-title-align-right {
        text-align: right;
    }

.panel-select-data .additional-data-advisory {
    font-size: 15px;
    font-weight: bold;
    margin-left: 10px;
}

.panel-select-data td.column3 {
    border-left: 1px solid #000000;
    vertical-align: middle;
    width: 140px;
}
    .panel-select-data .select-data {
        color: #7bb1b6;
        font-size: 23px;
        font-weight: 400;
        line-height: 27px;
    }

    .panel-select-data .select-data:hover {
        font-weight: bolder;
    }

        .panel-select-data .select-data .chevron:before,
        .panel-select-data .select-data .chevron.bottom:before {
            margin-left: 8px;
            margin-top: 2px;
        }

    .panel-select-data .table > tbody > tr > td:first-child {
        vertical-align: middle;
        width: 220px;
    }

    .panel-select-data .panel-title {
        color: #37394b;
        font-size: 23px;
        font-weight: 900;
        line-height: 27px;
    }

    .panel-select-data .clear-selection {
        color: #7bb1b6;
        cursor: pointer;
        display: inline-block;
        font-weight: 400;
        font-size: 14px;
        padding-top: 14px;
        width: 100%;
    }

    .panel-select-data .panel-title-indicator-name {
        color: #37394b;
        font-size: 23px;
        font-weight: 900;
        line-height: 27px;
    }

    .panel-select-data .panel-title-selected-count {
        color: #37394b;
        font-size: 16px;
        font-weight: 900;
        line-height: 19px;
    }

    .panel-select-data .column3.active {
        background: #f8f8f8;
        border: none;
    }

    .panel-select-data div.indicator-attribute {
        display: inline-block;
        width: 25%;
    }

        .panel-select-data div.indicator-attribute .indicator-attribute-name {
            color: #000;
            display: inline-block;
            font-size: 14px;
            font-weight: 700;
            line-height: 17px;
            width: 100%;
        }

        .panel-select-data div.indicator-attribute .indicator-attribute-value {
            color: #000;
            display: inline-block;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            width: 100%;
        }

    .panel-select-data .row-select-indicator {
        background: #f8f8f8;
    }

    .panel-select-data .row-select-indicator .select-panel-search-catalogue-input-container {
        border-bottom: 2px solid #37394b;
        padding: 15px 0;
        width: 100%;
    }

        .panel-select-data .row-select-indicator .select-panel-search-catalogue-input-container .input-group-text {
            background-color: #fff;
            border-bottom: 1px solid #ccc;
            border-left: 1px solid #ccc;
            border-radius: 0;
            border-right: none;
            border-top: 1px solid #ccc;
            height: 50px;
        }

        .panel-select-data .row-select-indicator .select-panel-search-catalogue-input-container input {
            border-bottom: 1px solid #ccc;
            border-left: 0;
            border-right: 0;
            border-top: 1px solid #ccc;
            height: 50px;
        }

        .panel-select-data .row-select-indicator .select-panel-search-catalogue-input-container .search-panel-input-container-button {
            height: 50px;
            border-bottom: 1px solid #ccc;
            border-left: 0;
            border-right: 1px solid #ccc;
            border-top: 1px solid #ccc;
            padding: 5px;
        }

        .panel-select-data .row-select-indicator .column1-search {
            text-align: right;
        }

        .panel-select-data .row-select-indicator .column1-search.center-vertically {
            vertical-align: middle;
        }

        .panel-select-data .row-select-indicator .column1-search .select-panel-row-title {
            color: #37394b;
            font-size: 18px;
            font-weight: 900;
            line-height: 21px;
        }