.panel-frequently-visited {
    width: 100%;
}

    .panel-frequently-visited ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .panel-frequently-visited ul > li {
        display: inline;
        margin-right: 25px;
    }

.panel-frequently-visited .panel-title {
        border-bottom: 2px solid #37394b;
        padding: 10px 0;
        width: 100%;
    }

    .panel-frequently-visited .panel-title .title {
        display: inline-block;
        font-family: 'Gibson';
        font-size: 23px;
        font-style: normal;
        font-weight: 900;
        line-height: 27px;
    }

    .panel-frequently-visited .panel-title .type-selection {
        display: inline-block;
        margin-top: 20px;
    }

    .panel-frequently-visited .row-title .title {
        color: #37394b;
        font-size: 23px;
        font-weight: 900;
        line-height: 27px;
    }

    .panel-frequently-visited .row-link-see-all .title {
        color: #7bb1b6;
        font-size: 16px;
        font-style: normal;
        line-height: 19px;
    }