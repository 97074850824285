.category-container {
}

    .category-container .category-name {
        color: #37394b;
        display: inline-block;
        font-family: 'Gibson';
        font-size: 23px;
        font-style: normal;
        font-weight: 900;
        line-height: 27px;
        width: 100%;
    }

    .category-container .category-main-bar {
        background-color: #7bb1b6;
        height: 6px;
        margin: 20px 0;
        padding: 2px 0;
    }

.category-overview {
    color: #7bb1b6;
    display: inline-block;
    font-family: 'Gibson';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    width: 100%;
}

    .category-overview:hover {
        font-weight: bolder;
        text-decoration: underline;
    }

.category-container td.cell-category {
    width: 20%;
}

.category-container td.cell-category-subcategories {
    width: 80%;
}

    .category-container td.cell-category-subcategories .category-subcategory {
        border-bottom: 1px solid #c6c6c6;
        color: #37394b;
        display: inline-block;
        margin-left: 30px;
        padding: 18px 0;
        width: calc(50% - 30px);
    }

        .category-container td.cell-category-subcategories .category-subcategory a {
            color: #37394b;
        }

        .category-container td.cell-category-subcategories .category-subcategory:hover {
            border-bottom: 1px solid #7bb1b6;
            color: #7bb1b6;
        }

            .category-container td.cell-category-subcategories .category-subcategory:hover a {
                color: #7bb1b6;
            }

        .category-container td.cell-category-subcategories .category-subcategory .subcategory-name {
            display: inline-block;
            font-family: 'Gibson';
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 21px;
            width: calc(100% - 30px);
        }

        .category-container td.cell-category-subcategories .category-subcategory .subcategory-chevron {
            color: #7bb1b6;
            display: inline-block;
            padding-top: 2px;
            width: 30px;
        }

    .category-container td.cell-category-subcategories .category-subcategory-more-less {
        color: #7bb1b6;
        display: inline-block;
        font-family: 'Gibson';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        margin: 20px 0 0 30px;
        width: calc(100% - 30px);
    }

        .category-container td.cell-category-subcategories .category-subcategory-more-less:hover {
            font-weight: bolder;
            text-decoration: underline;
        }

        .category-container td.cell-category-subcategories .category-subcategory-more-less .more-less-text {
        }

        .category-container td.cell-category-subcategories .category-subcategory-more-less .more-less-chevron {
            margin-left: 10px;
        }

.breadcrumb a {
    color: #7bb1b6;
}
